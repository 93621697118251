import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Content, { HTMLContent } from '../components/Content';
import BannerHeader from '../components/BannerHeader';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export const PrivacyPageTemplate = ({ header, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <SEO title="Privacy Policy" pathname="/privacy-policy" />
      <section className="section">
        <div className="container content markdown-content">
          <PageContent className="markdown-content" content={content} />
        </div>
      </section>
    </div>
  );
};

PrivacyPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PrivacyPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout>
      <PrivacyPageTemplate
        contentComponent={HTMLContent}
        content={markdownRemark.html}
      />
    </Layout>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPage;

export const privacyPageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      html
    }
  }
`;
